import React from 'react';
import { PostSnippet } from '../../../types';
import { HeroPostDesktop } from './desktop';
import { HeroPostMobile } from './mobile';

export const HeroPost = ({ data }: { data?: PostSnippet }) =>
  data ? (
    <>
      <HeroPostDesktop {...data} />
      <HeroPostMobile {...data} />
    </>
  ) : null;
