import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title: string;
  description: string;
}

interface Query {
  site: {
    siteMetadata: {
      defaultTitle: string;
      titleTemplate: string;
      defaultDescription: string;
      siteUrl: string;
      name: string;
    };
  };
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        name
      }
    }
  }
`;

export const SEO = ({ title, description }: SEOProps) => {
  const { pathname } = useLocation();
  const { site }: Query = useStaticQuery(query);

  const { defaultTitle, titleTemplate, defaultDescription, siteUrl, name } = site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname}`
  };

  // Alphabetical by name.
  const meta = [
    { name: 'description', content: seo.description },
    { name: 'title', content: `${seo.title} | Own Up Resources` },
    { name: 'og:description', content: seo.description },
    { name: 'og:site_name', content: name },
    { name: 'og:type', content: pathname ? 'article' : 'website' },
    { name: 'og:url', content: seo.url },
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: `${seo.title} | Own Up Resources` },
    { name: 'twitter:description', content: seo.description },
    { name: 'referrer', content: 'no-referrer' }
  ];

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate} meta={meta}>
      <html lang="en" />
      <link
        rel="canonical"
        key={seo.url}
        href={seo.url}
        data-baseprotocol="https:"
        data-basehost="resources.ownup.com"
      />
    </Helmet>
  );
};
