import { OwnUpBodyMedium, PropsWithTheme } from '@rategravity/own-up-component-library-new';
import React from 'react';
import styled from 'styled-components';
import { kebab } from '../../module/kebab';

const CategoryLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

const Link = styled.a`
  color: ${({ theme }: PropsWithTheme<{}>) => theme.underlineLinkColors.linkFontColor};
  text-decoration: none;
`;

export const CategoryLinks = ({ tags }: { tags: string[] }) => (
  <CategoryLinksWrapper>
    {tags.map((tag, index) => (
      <Link href={`/tag/${kebab(tag)}`} key={index}>
        <OwnUpBodyMedium variant="body1">{tag}</OwnUpBodyMedium>
      </Link>
    ))}
  </CategoryLinksWrapper>
);
