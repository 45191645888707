import { OwnUpGridWrapper, PropsWithTheme } from '@rategravity/own-up-component-library-new';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled, { css } from 'styled-components';
import { PostSnippet } from '../../../types';
import { Content, contentWrapperMixin } from './elements';

const layoutMixin = css`
  flex-direction: column;
  display: flex;
`;

const Section = styled.section`
  ${layoutMixin};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5, 0, 0)};
`;

const HeroImage = ({ img, imgAlt }: { img?: IGatsbyImageData; imgAlt: string }) =>
  img ? (
    <GatsbyImage
      image={img}
      alt={imgAlt}
      objectFit="cover"
      objectPosition={'50% 50%'}
      style={{
        display: 'block',
        maxWidth: '100%'
      }}
    />
  ) : null;

const HeroText = styled.div`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.primary.main};
`;

const ContentWrapper = styled.div`
  ${contentWrapperMixin}
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2, 0, 5)};
`;

export const HeroPostMobile = ({ title, summary, href, img, imgAlt }: PostSnippet) => (
  <Section aria-label="hero article">
    <HeroImage img={img} imgAlt={imgAlt || title} />
    <HeroText>
      <OwnUpGridWrapper>
        <ContentWrapper>
          <Content title={title} summary={summary} href={href} />
        </ContentWrapper>
      </OwnUpGridWrapper>
    </HeroText>
  </Section>
);
