import {
  OwnUpFillButtonPrimary,
  OwnUpGridWrapper,
  PropsWithTheme
} from '@rategravity/own-up-component-library-new';
import { ArrowRightIcon } from '@rategravity/own-up-component-library-new/icon-library/system-icons/standard-icons/arrow-right';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const NextPageContainer = styled.div`
  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.background.default};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(6, 0, 8)};
`;

const GridWrapper = styled(OwnUpGridWrapper)`
  display: flex;
  justify-content: flex-end;
`;

export const NextPage = () => (
  <NextPageContainer>
    <GridWrapper>
      <Link to="/page/2">
        <OwnUpFillButtonPrimary icon={<ArrowRightIcon />} iconPosition="right">
          Next
        </OwnUpFillButtonPrimary>
      </Link>
    </GridWrapper>
  </NextPageContainer>
);
