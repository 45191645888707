import { OwnUpGridWrapper, PropsWithTheme } from '@rategravity/own-up-component-library-new';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { PostSnippet } from '../../../types';
import { Content, contentWrapperMixin } from './elements';

const layoutMixin = {
  gridTemplateColumns: '[heroImage] 45% [heroText] auto',
  display: 'grid',
  height: '528px'
};

const Section = styled.section`
  ${layoutMixin}
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(6)}px`};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const HeroImageWrapper = styled.div`
  grid-column-start: heroImage;
  overflow: hidden;
  border-top-left-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  border-bottom-left-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  img {
    max-width: unset;
    height: 100%;
  }
`;

const HeroImage = ({ img, imgAlt }: { img?: IGatsbyImageData; imgAlt: string }) =>
  img ? <GatsbyImage image={img} alt={imgAlt} style={{ height: '100%' }} /> : null;

const HeroTextWrapper = styled.div`
  ${contentWrapperMixin}

  background-color: ${({ theme }: PropsWithTheme<{}>) => theme.palette.primary.main};
  grid-column-start: text;
  justify-content: center;
  border-top-right-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  border-bottom-right-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 6)};
`;

export const HeroPostDesktop = ({ title, summary, href, img, imgAlt }: PostSnippet) => (
  <OwnUpGridWrapper>
    <Section aria-label="hero article">
      <HeroImageWrapper>
        <HeroImage img={img} imgAlt={imgAlt || title} />
      </HeroImageWrapper>
      <HeroTextWrapper>
        <Content title={title} summary={summary} href={href} />
      </HeroTextWrapper>
    </Section>
  </OwnUpGridWrapper>
);
