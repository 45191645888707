import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpSmallSubheadlineMedium,
  PropsWithTheme,
  WHITE_100
} from '@rategravity/own-up-component-library-new';
import React from 'react';
import styled from 'styled-components';
import { PostSnippet } from '../../types';
import { CategoryLinks } from '../category-links';
import { ReadMoreLink } from '../read-more-link';

const ArticlesListContainer = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
`;

const Tile = styled.div`
  background-color: ${WHITE_100};
  border-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  height: 100%;
  min-height: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(28)}px`};
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 3)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

export type Context = 'featured' | 'normal';
export interface ArticlesList {
  posts: PostSnippet[];
  context: Context;
  home?: boolean;
}

export const ArticlesListItem = ({
  href,
  title,
  tags,
  summary,
  context,
  home
}: PostSnippet & { context: Context; home?: boolean }) => (
  <Tile
    role="button"
    onClick={() => {
      if (typeof window !== 'undefined') {
        window.location.href = href;
      }
    }}
  >
    <CategoryLinks tags={tags} />
    <OwnUpSmallSubheadlineMedium variant={home ? 'h3' : 'h2'} style={{ fontSize: '24px' }}>
      {title}
    </OwnUpSmallSubheadlineMedium>
    <OwnUpBody variant="body1">{summary}</OwnUpBody>
    <ReadMoreLink link={href} context={context} title={title} />
  </Tile>
);

export const ArticlesList = ({ posts, context, home }: ArticlesList) => (
  <ArticlesListContainer>
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="legacy">
        {posts.map((post, index) => (
          <OwnUpGridItem xs={12} md={6} key={index}>
            <ArticlesListItem {...post} context={context} home={home} />
          </OwnUpGridItem>
        ))}
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  </ArticlesListContainer>
);
