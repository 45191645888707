import { TrackingInstance, useTrackingInstance } from '@rategravity/1pt-lib';
import {
  MOSS_50,
  OwnUpFillButtonPrimary,
  OwnUpGridWrapper,
  OwnUpSlimButtonSecondary
} from '@rategravity/own-up-component-library-new';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { kebab } from '../../../module/kebab';
import { HomeSection } from '../home-section';
import {
  CategoryListWrapper as CategoryListLinksWrapper,
  CategoryListWrapperDesktop,
  CategoryListWrapperMobile
} from './styled';

const CategoryLink = ({
  tag,
  trackingInstance
}: {
  tag: string;
  trackingInstance: TrackingInstance;
}) => {
  const onClick = () => {
    trackingInstance.track('Blog Category Clicked', { category: tag }, 'v1');
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        window.location.href = `/tag/${kebab(tag)}`;
      }
    }, 300);
  };

  return <OwnUpSlimButtonSecondary onClick={onClick}>{tag}</OwnUpSlimButtonSecondary>;
};

const CategoryListToggle = ({
  showAll,
  setShowAll
}: {
  showAll: boolean;
  setShowAll: (value: React.SetStateAction<boolean>) => void;
}) => {
  const trackingInstance = useTrackingInstance();

  return (
    <div style={{ display: 'flex', width: '100%', justifyContent: 'center', paddingTop: '25px' }}>
      <OwnUpFillButtonPrimary
        onClick={() => {
          trackingInstance.track(
            `Blog Category ${showAll ? 'Show All' : 'Show Less'} Clicked`,
            {},
            'v1'
          );
          setShowAll(!showAll);
        }}
      >
        {showAll ? 'Show Less' : 'Show All'}
      </OwnUpFillButtonPrimary>
    </div>
  );
};

const CategoryListLinks = ({ tags }: { tags: string[] }) => {
  const trackingInstance = useTrackingInstance();
  const tagLinks = tags.map((tag, key) => (
    <CategoryLink key={key} {...{ tag, trackingInstance }} />
  ));
  return <CategoryListLinksWrapper>{tagLinks}</CategoryListLinksWrapper>;
};

const CategoryListContent = ({
  alphabetizedTags,
  popularTags,
  initialShowAll = true
}: {
  alphabetizedTags: string[];
  popularTags: string[];
  initialShowAll?: boolean;
}) => {
  const [showAll, setShowAll] = useState(initialShowAll);

  const showTags = showAll ? alphabetizedTags : popularTags.slice(0, 4);

  return (
    <>
      <CategoryListLinks tags={showTags} />
      <CategoryListToggle {...{ showAll, setShowAll }} />
    </>
  );
};

export interface TagQueryData {
  allMarkdownRemark: {
    group: {
      tag: string;
      totalCount: number;
    }[];
  };
}

export const PureCategoryList = ({
  alphabetizedTags,
  popularTags
}: {
  alphabetizedTags: string[];
  popularTags: string[];
}) => (
  <HomeSection title="Categories" backgroundColor={MOSS_50}>
    <CategoryListWrapperDesktop>
      <OwnUpGridWrapper>
        <CategoryListContent {...{ alphabetizedTags, popularTags }} />
      </OwnUpGridWrapper>
    </CategoryListWrapperDesktop>
    <CategoryListWrapperMobile>
      <OwnUpGridWrapper>
        <CategoryListContent {...{ alphabetizedTags, popularTags, initialShowAll: false }} />
      </OwnUpGridWrapper>
    </CategoryListWrapperMobile>
  </HomeSection>
);

export const CategoryList = () => {
  const {
    allMarkdownRemark: { group }
  }: TagQueryData = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___tags) {
          tag: fieldValue
          totalCount
        }
      }
    }
  `);

  const alphabetizedTags = [...group]
    .sort((a, b) => (a.tag < b.tag ? -1 : 1))
    .map(({ tag }) => tag);
  const popularTags = [...group]
    .sort((a, b) => (a.totalCount < b.totalCount ? 1 : -1))
    .map(({ tag }) => tag);

  return <PureCategoryList {...{ alphabetizedTags, popularTags }} />;
};
