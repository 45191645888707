import { IconLink, PropsWithTheme } from '@rategravity/own-up-component-library-new';
import { ArrowRightIcon } from '@rategravity/own-up-component-library-new/icon-library/system-icons/standard-icons/arrow-right';
import React from 'react';
import styled from 'styled-components';
import { Context } from '../articles-list';

const IconLinkWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

export const ReadMoreLink = ({
  link,
  title,
  context
}: {
  link: string;
  title: string;
  context: Context;
}) => (
  <IconLinkWrapper>
    <IconLink
      icon={<ArrowRightIcon />}
      link={`${link}?utm_content=${context}`}
      iconPlacement="right"
      aria-label={title}
    >
      Read more
    </IconLink>
  </IconLinkWrapper>
);
