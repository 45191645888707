import { PropsWithTheme } from '@rategravity/own-up-component-library-new';
import styled from 'styled-components';

export const CategoryListWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  gap: 12px;
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  }
`;

export const CategoryListWrapperMobile = styled.div`
  display: block;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    display: none;
  }
`;

export const CategoryListWrapperDesktop = styled.div`
  display: block;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;
