import { MOSS_50 } from '@rategravity/own-up-component-library-new';
import React from 'react';
import { PostSnippet } from '../../../types';
import { ArticlesList } from '../../articles-list';
import { HomeSection } from '../home-section';

export interface LatestArticles {
  latestArticles: PostSnippet[];
}

export const LatestArticles = ({ latestArticles }: LatestArticles) => (
  <HomeSection title="Latest articles" backgroundColor={MOSS_50}>
    <ArticlesList posts={latestArticles} context="normal" home />
  </HomeSection>
);
