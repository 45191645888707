import { useTrackingInstance } from '@rategravity/1pt-lib';
import { Footer, myConverter, NavBar, wwwConverter } from '@rategravity/marketing-components';
import { PropsWithTheme } from '@rategravity/own-up-component-library-new';
import React, { Fragment, PropsWithChildren } from 'react';
import styled from 'styled-components';

// The old header is using the original breakpoints from www.
export const LEGACY_MEDIUM_BREAKPOINT = '1024px';

const my = myConverter(`https://${process.env.GATSBY_MY}`);
const www = wwwConverter(`https://${process.env.GATSBY_WWW}`);

const MainContent = styled.main`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    padding-top: 4.5rem;
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('lg')} {
    padding-top: 5rem;
  }
`;

const ContentWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(5)}px`};
`;

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const trackingInstance = useTrackingInstance();

  return (
    <Fragment>
      <ContentWrapper>
        <NavBar {...{ www, my, isMainSite: false, trackingInstance }} />
        <MainContent>{children}</MainContent>
        <Footer {...{ www, isMainSite: false, trackingInstance }} />
      </ContentWrapper>
    </Fragment>
  );
};
