import {
  OwnUpFillButtonPrimaryAccent,
  PropsWithTheme
} from '@rategravity/own-up-component-library-new';
import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  width: fit-content;

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('xl')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  }

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(3)}px`};
  }
`;

export const CTA = ({ href, title }: { href: string; title: string }) => (
  <ButtonWrapper>
    <OwnUpFillButtonPrimaryAccent aria-label={title} href={`${href}?utm_content=hero`}>
      Read more
    </OwnUpFillButtonPrimaryAccent>
  </ButtonWrapper>
);
