import { OwnUpSubheadlineMedium, PropsWithTheme } from '@rategravity/own-up-component-library-new';
import React from 'react';
import styled from 'styled-components';
import { kebab } from '../../../module/kebab';

const Section = styled.section`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(8)}px`};

  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.down('sm')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(6)}px`};
  }
`;

const Headline = styled(OwnUpSubheadlineMedium)`
  text-align: center;
`;

export const HomeSection = ({
  title,
  backgroundColor,
  children
}: React.PropsWithChildren<{ title: string; backgroundColor?: string }>) => {
  const ariaId = `section-title-${kebab(title)}`;

  return (
    <Section aria-labelledby={ariaId} style={{ backgroundColor }}>
      <Headline variant="h2" id={ariaId}>
        {title}
      </Headline>
      {children}
    </Section>
  );
};
