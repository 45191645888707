import { Post } from '../types';

export const mapPostData = ({ frontmatter, fields }: Post) => ({
  title: frontmatter.title,
  summary: frontmatter.description,
  href: fields.slug,
  img: frontmatter.img?.childImageSharp.gatsbyImageData,
  imgAlt: frontmatter.imgAlt || undefined,
  tags: frontmatter.tags || [],
  publishedDate: new Date(frontmatter.publishedDate)
});
