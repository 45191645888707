import {
  MOSS_100,
  OwnUpGridWrapper,
  OwnUpHeadlineBook,
  OwnUpOverline
} from '@rategravity/own-up-component-library-new';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  CategoryList,
  FeaturePosts,
  HeroPost,
  LatestArticles,
  NextPage
} from '../../components/home';
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';
import { mapPostData } from '../../module/map-post-data';
import { Post, PostSnippet } from '../../types';

export const pageQuery = graphql`
  query ($heroTitle: String!) {
    heroPost: allMarkdownRemark(
      filter: { fields: {}, frontmatter: { title: { eq: $heroTitle } } }
      limit: 1
      sort: { fields: frontmatter___publishedDate }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          tags
          title
          imgAlt
          description
          publishedDate
          img {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 1024
                quality: 90
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
    featuredPosts: allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___publishedDate], order: DESC }
      filter: { frontmatter: { featured: { eq: true }, title: { ne: $heroTitle } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          tags
          title
          imgAlt
          description
          publishedDate
          img {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 671
                quality: 90
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
    latestArticles: allMarkdownRemark(
      limit: 10
      sort: { fields: [frontmatter___publishedDate], order: DESC }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          tags
          title
          description
          publishedDate
        }
      }
    }
  }
`;

interface QueryData {
  heroPost: {
    nodes: Post[];
  };
  featuredPosts: {
    nodes: Post[];
  };
  latestArticles: {
    nodes: Post[];
  };
}

interface Home {
  data: QueryData;
}

const HelmetComponent = () => (
  <Helmet>
    <style>
      {`
      main {
        background-color: ${MOSS_100}
      }`}
    </style>
  </Helmet>
);

const Home = ({ data }: Home) => {
  const featuredPostData: PostSnippet[] = data.featuredPosts.nodes.map(mapPostData);

  // If there's no explicit hero, we will fall back on the first featured
  // post. If there are no featured posts, we display nothing for the hero
  const heroPostData: PostSnippet | undefined =
    data.heroPost.nodes.map(mapPostData).pop() || featuredPostData.pop();
  const latestArticlesData: PostSnippet[] = data.latestArticles.nodes.map(mapPostData);

  return (
    <>
      {process.env.STORYBOOK_ENV === 'true' ? null : (
        <SEO title="Home" description="The Own Up Blog" />
      )}
      <Layout>
        <HelmetComponent />
        <OwnUpGridWrapper>
          <OwnUpOverline>OWN UP BLOG</OwnUpOverline>
          <OwnUpHeadlineBook variant="h1">Insights from our team</OwnUpHeadlineBook>
        </OwnUpGridWrapper>
        <HeroPost data={heroPostData} />
        <FeaturePosts featurePosts={featuredPostData} />
        <CategoryList />
        <LatestArticles latestArticles={latestArticlesData} />
        <NextPage />
      </Layout>
    </>
  );
};

//eslint-disable-next-line import/no-default-export
export default Home;
