import {
  OwnUpLargeBody,
  OwnUpLargeSubheadlineRegular,
  PropsWithTheme,
  WHITE_100
} from '@rategravity/own-up-component-library-new';
import React from 'react';
import { css } from 'styled-components';
import { CTA } from './cta';

export const contentWrapperMixin = css`
  color: ${WHITE_100};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
`;

export const Content = ({
  title,
  summary,
  href
}: {
  title: string;
  summary: string;
  href: string;
}) => (
  <React.Fragment>
    <OwnUpLargeSubheadlineRegular variant="h2">{title}</OwnUpLargeSubheadlineRegular>
    <OwnUpLargeBody variant="body1">{summary}</OwnUpLargeBody>
    <CTA href={href} title={title} />
  </React.Fragment>
);
