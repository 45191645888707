import {
  OwnUpBody,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridWrapper,
  OwnUpSmallSubheadlineMedium,
  PropsWithTheme,
  WHITE_100
} from '@rategravity/own-up-component-library-new';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { PostSnippet } from '../../../types';
import { CategoryLinks } from '../../category-links';
import { ReadMoreLink } from '../../read-more-link';
import { HomeSection } from '../home-section';

export interface FeaturePosts {
  featurePosts?: PostSnippet[];
}

const CardsWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(4)}px`};
  margin-bottom: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(12)}px`};
`;

const Card = styled.div`
  border-radius: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(2)}px`};
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 10%), 0 4px 6px -2px rgb(0 0 0 / 5%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledImage = styled(GatsbyImage)`
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    height: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(26.5)}px`};
  }
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('xs')} {
    height: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(24)}px`};
  }
`;

const Tile = styled.div`
  background-color: ${WHITE_100};
  flex-grow: 1;
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4, 3)};
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }: PropsWithTheme<{}>) => `${theme.spacing(1)}px`};
`;

export const FeaturePost = ({ title, summary, href, img, imgAlt, tags }: PostSnippet) => (
  <OwnUpGridItem xs={12} md={4}>
    <Card
      role="button"
      onClick={() => {
        if (typeof window !== 'undefined') {
          window.location.href = href;
        }
      }}
    >
      {img ? <StyledImage image={img} alt={imgAlt || title} /> : null}
      <Tile>
        <ContentWrapper>
          <CategoryLinks tags={tags} />
          <OwnUpSmallSubheadlineMedium variant="h3">{title}</OwnUpSmallSubheadlineMedium>
          <OwnUpBody variant="body1">
            {summary.length < 180 ? summary : summary.substring(0, 180) + '...'}
          </OwnUpBody>
          <ReadMoreLink link={href} context="featured" title={title} />
        </ContentWrapper>
      </Tile>
    </Card>
  </OwnUpGridItem>
);

export const FeaturePosts = ({ featurePosts }: FeaturePosts) =>
  featurePosts ? (
    <HomeSection title="Featured">
      <CardsWrapper>
        <OwnUpGridWrapper>
          <OwnUpGridContainer variant="legacy">
            {featurePosts.map((featurePost, index) => (
              <FeaturePost {...featurePost} key={index} />
            ))}
          </OwnUpGridContainer>
        </OwnUpGridWrapper>
      </CardsWrapper>
    </HomeSection>
  ) : null;
